@for (opt of optionsCopy; track opt) {
  <ng-container *ngTemplateOutlet="optionTemplate; context: { option: opt }"></ng-container>
}

<ng-template
  #optionTemplate
  let-option="option"
>
  <div
    class="treeview-picker_option"
    [class.expandable]="option.suboptions"
  >
    @if (option.suboptions) {
      <div
        class="treeview-picker_toggle-btn"
        [appFocusable]
        (click)="option.expanded = !option.expanded"
      >
        <img
          [appAssetSrc]="
            option.expanded ? 'assets/svg/common/arrow-down-grey.svg' : 'assets/svg/common/arrow-right-grey.svg'
          "
        />
      </div>
    }
    @if (!(singleSelect && option.suboptions)) {
      <app-checkbox
        [label]="option.label | translate"
        [disabled]="isDisabled"
        [checked]="isOptionSelected(option)"
        [intermediate]="isOptionIntermediate(option)"
        (change)="changeSelection($event, option)"
      >
      </app-checkbox>
    }
    @if (singleSelect && option.suboptions) {
      <label
        [appFocusable]
        (click)="option.expanded = !option.expanded"
      >
        {{ option.label | translate }}
      </label>
    }
  </div>
  @if (option.suboptions && option.expanded) {
    <div class="tree-view-picker_suboptions">
      @for (opt of option.suboptions; track opt) {
        <ng-container *ngTemplateOutlet="optionTemplate; context: { option: opt }"></ng-container>
      }
    </div>
  }
</ng-template>
